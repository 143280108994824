import React from 'react'
import { products } from '../constant';

const ProductDetail = () => {
  console.log('ProductDetail', window.location.pathname)
  const product = products.find(product => product.url === window.location.pathname.split('/')[1])
  console.log('product', product)
  return (
    <div class="container product-wrapper">
      <div class="row">
        <div class="col-md-6 ">
          <img src={product.image} alt="" />
        </div>
        <div class="col-md-6  ">
          <div class="product-dtl product-flex ">
            <div class="product-info">
              <h2 class="product-name">{product.name}</h2>
            </div>
            {
              product.description.map((desc, index) => (
                <p key={index}>{desc}</p>
              ))
            }
            <div>
              <div>
                <h4>Specifications</h4>
              </div>
              <table>
                {product.specifications.width && (
                  <tr>
                    <td>Width</td>
                    <td>{product.specifications.width}</td>
                  </tr>
                )
                }
                {product.specifications.length && (
                  <tr>
                    <td>Length</td>
                    <td>{product.specifications.length}</td>
                  </tr>
                )
                }
                {product.specifications.weight && (
                  <tr>
                    <td>Weight</td>
                    <td>{product.specifications.weight}</td>
                  </tr>
                )
                }
                {product.specifications.mesh && (
                  <tr>
                    <td>Mesh</td>
                    <td>{product.specifications.mesh}</td>
                  </tr>
                )
                }
                {product.specifications.denier && (
                  <tr>
                    <td>Denier</td>
                    <td>{product.specifications.denier}</td>
                  </tr>
                )
                }
                {product.specifications.lamination && (
                  <tr>
                    <td>Lamination</td>
                    <td>{product.specifications.lamination}</td>
                  </tr>
                )
                }
                {product.specifications.gusset && (
                  <tr>
                    <td>Gusset</td>
                    <td>{product.specifications.gusset}</td>
                  </tr>
                )
                }
                {product.specifications.UVStabilization && (
                  <tr>
                    <td>UV Stabilization</td>
                    <td>{product.specifications.UVStabilization}</td>
                  </tr>
                )
                }
                {product.specifications.printing && (
                  <tr>
                    <td>Printing</td>
                    <td>{product.specifications.printing}</td>
                  </tr>
                )
                }
                {product.specifications.strength && (
                  <tr>
                    <td>Strength</td>
                    <td>{product.specifications.strength}</td>
                  </tr>
                )
                }
                {product.specifications.packing && (
                  <tr>
                    <td>Packing</td>
                    <td>{product.specifications.packing}</td>
                  </tr>
                )
                }
                {product.specifications.gsm && (
                  <tr>
                    <td>GSM</td>
                    <td>{product.specifications.gsm}</td>
                  </tr>
                )
                }
                {product.specifications.colours && (
                  <tr>
                    <td>Colours</td>
                    <td>{product.specifications.colours}</td>
                  </tr>
                )
                }
                {product.specifications.bag_opening && (
                  <tr>
                    <td>Bag Opening</td>
                    <td>{product.specifications.bag_opening}</td>
                  </tr>
                )
                }
                {product.specifications.liner && (
                  <tr>
                    <td>Liner</td>
                    <td>{product.specifications.liner}</td>
                  </tr>
                )
                }
                {product.specifications.bopp_coated && (
                  <tr>
                    <td>BOPP Coated</td>
                    <td>{product.specifications.bopp_coated}</td>
                  </tr>
                )
                }
                {product.specifications.open_platform && (
                  <tr>
                    <td>Open Platform</td>
                    <td>{product.specifications.open_platform}</td>
                  </tr>
                )
                }
                {product.specifications.stitching && (
                  <tr>
                    <td>Stitching</td>
                    <td>{product.specifications.stitching}</td>
                  </tr>
                )
                }
                {product.specifications.top && (
                  <tr>
                    <td>Top</td>
                    <td>{product.specifications.top}</td>
                  </tr>
                )
                }
                {product.specifications.bottom && (
                  <tr>
                    <td>Bottom</td>
                    <td>{product.specifications.bottom}</td>
                  </tr>
                )
                }
                {product.specifications.drawstring && (
                  <tr>
                    <td>Draw String</td>
                    <td>{product.specifications.drawstring}</td>
                  </tr>
                )
                }
                {product.specifications.label && (
                  <tr>
                    <td>Label</td>
                    <td>{product.specifications.label}</td>
                  </tr>
                )
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
import React from 'react'
import vector from '../assets/images/vector_products.png';
import { products } from '../constant';


const ProductsList = () => {
  return (
    <section className='products-list-section container-fluid' id="products">
      <div className='products-list-area container'>
        <div className='top-section'>
          <h1 className='title'> Our Products </h1>
        </div>
        <div className='bottom-section'>
          {products.map((product, index) => (
            <div className='product-detail'>
              <img src={product.image} alt={product.name} key={index} onClick={() => window.location.assign(`/${product.url}`)} />
              <h3>{product.name}</h3>
            </div>
          ))}
        </div>
      </div>
      <img src={vector} className='vector' alt='vector' />
    </section>
  )
}

export default ProductsList
import roll from './assets/images/product_roll.png';
import sack from './assets/images/product_plastic_sack.png';
import ldpeBags from './assets/images/pdf_images/image-008.png';
import lenoBags from './assets/images/product_transparent_sack.png';
import spunBound from './assets/images/pdf_images/image-010.png';
export const products = [
  {
    id: 1,
    name: "PP/HDPE Woven Fabric Rolls",
    url: 'pp-hdpe-woven-fabric-rolls',
    description: [
      'We manufacture high quality laminated and un-laminated PP/HDPE Woven fabrics. We manufacture Flat / Circular HDPE(High Density Poly Ethylene) / PP(Poly Propylene) fabrics forconverting into packaging, Sacks, sheltering & covering products.The Fabrics is available in Laminated and Un - laminated form through our inhouse lamination facility.We provide 100 % customized PP fabric including parameters such as size, GSM, colour, mesh, UV and other parameters to required specifications, in total we are producing 80 varieties of woven sacks.',
      'P & P manufacture 2000 Tons of PP Woven Fabric per month for Indian domestic market and for Exports.We produce PP / HDPE woven fabric using internationally recognized quality control, maintaining strict quality standards, making sure our customers receive world class quality Polypropylene fabric(PP fabric) with the right technical construction and minimum defects.',
    ],
    specifications: {
      weight: "30 GSM to 140 GSM and above",
      width: 'Circular Form - 25 cm to 150 cm (10" to 60") Open Flat Form - 25 cm to 300 cm (10" to 120")',
      length: 'Available in Roll Form',
      mesh: '4 x 4 to 16 x 16',
      denier: '400 Denier to 2000 Denier and above',
      lamination: '12 micron to 50 micron and above (10 GSM to 48 GSM)',
      gusset: 'Gusset on each side (as per requirement)',
      UVStabilization: "Yes (if required) - 200 hours to 1600 hours",
      printing: 'One & Both side - One/Two/Three colors. Available in roll form as per design and artwork',
      strength: 'We can match your strength in warp way and weft way.',
      packing: 'Rolls up to 5000 meters or as per Customer Requirements',
    },
    image: roll
  },
  {
    id: 2,
    name: "PP/HDPE Woven Sacks",
    url: 'pp-hdpe-woven-sacks',
    description: [
      'We manufactures and exports various type of PP Woven Sacks and Small Bags that are available in polypropylene and high density polyethylene as woven sacks and small bags. Our bags are manufactured using the virgin raw material, woven bags are manufactured in different sizes and specifications to meet the varied needs of customers for packaging.',
      'Our woven pp sacks are strong, breathable and extremely cost effective - ideal for Nuts & Bolts, Feeds & Seeds, Coal & Logs, Shellfish, Waste Paper & many other applications.Our Bag finishing Unit is producing more than three hundred thousand sacks per day of different quality from 30 cm to 150 cm tubular / circular width, from 400 Denier to 2000 Denier.',
      'P & P has a specialty in producing woven sacks with gusset and valve.The gusset can be printed to get better visibility of brand.We can offer woven sacks in transparent, White and various other colors.We can also offer anti - slip sacks, sacks with in liners and sacks with perforation. Typically woven sacks can be used for carrying sand, food grains, grass seeds, fertilizers, sugar, beans, rice, gravel & coal in the construction, chemical, agriculture, mining and building material industry.',
      "Don't worry if you can't see what you want here, we offer 100 % custom designed polypropylenewoven bags / sacks as per the requirements and specifications of our customers."
    ],
    specifications: {
      weight: 'As per Required size',
      width: '12" to 80"',
      denier: '400 to 2000',
      gsm: '40 GSM and above',
      mesh: '4 x 4 to 16 x 16',
      length: 'As per Required size',
      colours: 'Same shade as per customer requirement',
      bag_opening: 'As per requirement (Top hemmed/Heat cut/Valve etc)',
      liner: 'As per requirement',
      lamination: 'As per requirement',
      UVStabilization: 'As per requirement',
      bopp_coated: 'As per requirement',
      packing: 'As per requirement',
    },
    image: sack
  },
  {
    id: 3,
    name: "PP/LDPE Bags",
    url: 'pp-ldpe-bags',
    description: [
      'P&P Packaging is one of the few suppliers who have been offering products which are developed in Karnataka, These special virgin LDPE bags which find its application in various solutions like specialty films, linings in medical capsule, tablets, API powder & pharmaceutical packaging manufacture etc. Our offered LDPE manufacturing process is SEDEX certified and we have a comprehensive system of quality control for tracing defective product batches and isolating potential quality issues in every step of manufacture. strength and breaking point analysis to ensure total field reliability. These moisture barrier LDPE bag uses the highest quality RIL approved raw materials which are consistent for every batch. LDPE bags are used as anti-static drum liners for medical product packing which meets FDA standards on all fronts. These liners have a tensile strength of over 1500 to 3000 Psi and tear strength of 450 lbs/inch. This material can be heat sealed in 3.5 seconds under 60 psi of pressure. Pharma Grade Bags - API, Bulk Drugs, Tablets, Capsules, Garment Bags, F&V Bags & Industrial Use',
    ],
    specifications: {
      width: '12" to 80"',
      open_platform: '3" to 40"',
      length: 'upto 90"',
      weight: '30 GSM to 140 GSM and above',
      lamination: 'As per requirement',
      gusset: 'Gusset on each side (as per requirement)',
      UVStabilization: 'yes (if required) - 200 hours to 1600 hours',
      printing: 'One & Both side - upto 6 colors. Available in roll form as per design and artwork',
      strength: 'We can match your strength in warp way and weft way.',
      packing: 'As per requirement',
    },
    image: ldpeBags
  },
  {
    id: 4,
    name: "Leno Sacks",
    url: 'leno-sacks',
    description: [
      'Woven bags or leno bags are ideal for packaging potatoes, onions, carrots, shellfish or firewood. Our Leno bags and fabric are very strong, form-stable and breathable. We have started manufacturing leno bags/fabrics in 2005 and supplying within India and also exporting to Europe and USA on regular basis. Our leno bags are made from the best plastic granules providing superb glossy and translucent finish. We have the capacity to supply more than 3 million leno bags per month and 300 MT leno fabric we are producing every month.',
    ],
    specifications: {
      weight: 'As per Requirement',
      length: 'As per Requirement',
      mesh: '4 x 4 to 5 x 5',
      gsm: '30 to 110',
      colours: 'All colours available',
      stitching: 'Generally tubular bags or Lstiched bags',
      top: 'Mouth hemmed',
      bottom: 'Folding with single or double stitch',
      drawstring: 'with or without drawstring',
      label: 'optional on the bag',
    },
    image: lenoBags
  },
  {
    id: 5,
    name: "PP Spunbond Non Woven Fabric",
    url: 'pp-spunbond-non-woven-fabric',
    description: [
      'Polypropylene based nonwoven fabrics are increasingly used as industrial and household fiber as it possesses important characteristics such as absorbency, resilience, softness, strength, and elasticity. Our Disposable and durable PP spun Bond non-woven fabrics comes with excellent characteristics such as Tear resistance, light weight, excellant water and air permiability, absorbent, soft and non allergic ',
    ],
    specifications: {
      minimum_gsm: '15',
      maximum_gsm: '145',
      width: '160cm',
      colours: 'All colours available',
    },
    image: spunBound
  },
]
import React from 'react'
import factory from '../assets/images/pdf_images/image-004.png'

const AboutUs = () => {
  return (
    <section className='about-us-section container-fluid' id="about-us">
      <div className='about-us-area container'>
        <div className='top-section'>
          <h1 className='title'>About Us</h1>
        </div>
        <div className='middle-section'>
          <div className='image-area'>
            <img src={factory} alt='person' />
          </div>
          <div className='content-area'>
            <div className='title'>
              <h4>Hi, Here we are</h4>
            </div>
            <div className='description'>
              <p>We are one of the reckoned and leading dealers of plastic granules, PP/HDPE woven sacks and  fabric, leno bags, polyethylene rolls, liners & other packaging solutions.</p>
            </div>
            <div className='description'>
              <p>Incepted in the year 1995 P&P has added several feathers on its hat by repeatedly offering exceptional quality bags and fabrics . We have extremely accomplished and skillful workers, who are committed in providing authentic products to our honurable clients.</p>
            </div>
            <div className='description'>
              <p>Since our inception, we have been regularly catering to the demands of the domestic market keeping apart with the international quality standards. We guarantee our clients a safe and opprtunity delivery of products.</p>
            </div>
          </div>

        </div>
        <div className='bottom-section'>
          <h4>“We commit only  when we can perform and <br /> we perform what we commit”</h4>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
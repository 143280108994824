import React, { useState } from 'react'
import logo from '../assets/images/logo.svg';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseMenu = () => {
    setIsOpen(false);
  };
  const mobileDevice = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <header className='container-fluid' id="home">
      <div className='header-area container'>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <ul>
          <li><a href='/#home'>Home</a></li>
          <li><a href='/#products'>Products</a></li>
          <li><a href="/#about-us" >About Us</a></li>
          <li><a href="/#contact-us" >Contact Us</a></li>
        </ul>
        {
          mobileDevice && (
            <nav>
              <div className="hamburger-icon" onClick={toggleMenu}>
                <div className={`line ${isOpen ? 'open' : ''}`} />
                <div className={`line ${isOpen ? 'open' : ''}`} />
                <div className={`line ${isOpen ? 'open' : ''}`} />
              </div>
              <ul className={`menu ${isOpen ? 'open' : ''}`}>
                {/* Add your navigation links here */}
                <li> <a href="/#home" onClick={handleCloseMenu}>Home </a></li>
                <li><a href="/#products" onClick={handleCloseMenu}>Products</a></li>
                <li><a href="/#about-us" onClick={handleCloseMenu} >About Us</a></li>
                <li><a href="/#contact-us" onClick={handleCloseMenu} >Contact Us</a></li>
                <div className="close-icon" onClick={handleCloseMenu}>
                  <div className="line" />
                  <div className="line" />
                </div>
              </ul>
            </nav>)
        }

      </div>
    </header>
  )
}

export default Header
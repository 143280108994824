import React from 'react'
import '../assets/styles/home.scss';
import { AboutUs, Banner, Clients, ContactUs, ExploreContent, ProductsList, Strategy, WhyUs } from '../components';


const Home = () => {

  return (
    <main>
      <Banner />
      <Strategy />
      <ExploreContent />
      <ProductsList />
      <AboutUs />
      <WhyUs />
      <Clients />
      <ContactUs />

    </main>
  )
}

export default Home
import React from 'react'
import { products } from '../constant';

const RelatedProduts = () => {
  const filteredProducts = products.filter(product => product.url !== window.location.pathname.split('/')[1])
  return (
    <section className='related-products-section container-fluid'>
      <div className='related-products container'>
        <div className="top-section">
          <h2>Related Products</h2>
        </div>
        <div className='bottom-section'>
          {
            filteredProducts.map((product, index) => (
              <div className='product-detail'>
                <img src={product.image} alt={product.name} onClick={() => window.location.assign(`/${product.url}`)} key={index} />
                <h3>{product.name}</h3>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default RelatedProduts
import React from 'react'
import quality from '../assets/images/quality 1.svg';
import agreement from '../assets/images/agreement 1.svg';
import earth from '../assets/images/planet-earth 1.svg';

const WhyUs = () => {
  return (
    <section className='why-us-section container-fluid' id="why-us">
      <div className='why-us-area container '>
        <div className='top-section'>
          <h1 className='title'>Why Us</h1>
        </div>
        <div className='bottom-section'>
          <div className='why-us-card'>
            <div className='img'>
              <img src={quality} alt='quality' />
            </div>
            <div className='content'>
              <h3>Best Quality</h3>
              <p>We prioritize delivering high -Quality
                products to our customers</p>
            </div>
          </div>
          <div className='why-us-card'>
            <div className='img'>
              <img src={agreement} alt='agreement' />
            </div>
            <div className='content'>
              <h3>Customer satisfaction</h3>
              <p>Understand customer needs and preferences and tailoring them  </p>
            </div>
          </div>
          <div className='why-us-card'>
            <div className='img'>
              <img src={earth} alt='earth' />
            </div>
            <div className='content'>
              <h3>Environmental consciousness</h3>
              <p>Promoting the reuse and recycling of materials to minimize waste</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyUs
import React, { useState, useEffect } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import flipkart from '../assets/images/article_banner_press_release_26_03_19_v1-01.jpg';
import amazon from '../assets/images/1200px-Amazon_logo.jpg';
import dunzo from '../assets/images/dunzo9232.jpg';
import delhivery from '../assets/images/IMG_20220505_225122-777x437.jpg';
import myntra from '../assets/images/myntra.png';
import reliance from '../assets/images/reliance.png';
import img11 from '../assets/images/clients/image-011.png';
import img12 from '../assets/images/clients/image-012.png';
import img15 from '../assets/images/clients/image-015.png';
import img16 from '../assets/images/clients/image-016.png';
import img17 from '../assets/images/clients/image-017.png';
import img22 from '../assets/images/clients/image-022.png';
import img32 from '../assets/images/clients/image-032.png';
const Clients = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, [width]);
  console.log(width);
  return (
    <section className='clients-section container-fluid'>
      <div className='clients-area '>
        <div className='top-section'>
          <h1 className='title'>Our Clients</h1>
        </div>
        <div className='bottom-section'>
          <Swiper
            spaceBetween={width > 768 ? 10 : 40}
            slidesPerView={width > 768 ? 4 : 2}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Navigation, Autoplay]}
            navigation={true}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
          >
            <SwiperSlide><img src={flipkart} alt='flipkart' width={200} content='fill' objectFit="contain" /></SwiperSlide>
            <SwiperSlide><img src={amazon} alt='amazon' width={200} /></SwiperSlide>
            <SwiperSlide><img src={dunzo} alt='dunzo' width={200} /></SwiperSlide>
            <SwiperSlide><img src={delhivery} alt='delhivery' width={200} /></SwiperSlide>
            <SwiperSlide><img src={myntra} alt='myntra' width={200} /></SwiperSlide>
            <SwiperSlide><img src={reliance} alt='myntra' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img11} alt='11' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img12} alt='12' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img15} alt='15' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img16} alt='16' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img17} alt='17' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img22} alt='22' width={200} /></SwiperSlide>
            <SwiperSlide><img src={img32} alt='32' width={200} /></SwiperSlide>


          </Swiper>
        </div>
      </div>
    </section >
  )
}

export default Clients
import React from 'react'
import roll from '../assets/images/pdf_images/image-001.png';
import cube1 from '../assets/images/Group 7.svg';
import cube2 from '../assets/images/Group 8.svg';

const ExploreContent = () => {
  return (
    <section className='explore-content-section container-fluid '>
      <div className='row'>
        <div className='content-area col-md-6 '>
          <div className='top-section'>
            <h4>We Explore to <br />Empower</h4>
          </div>
          <div className='middle-section'>
            <p>We provide 100% customized PP fabric including  parameters such as size ,GSM , colour, mesh, UV  and other parameters to required specifications,  in total we are producing 80 varieties of woven  sacks. </p>
          </div>
        </div>
        <div className='image-area col-md-6'>
          <img src={roll} alt="roll" />
        </div>
      </div>
      <img src={cube1} alt='cube' className='cube1' />
      <img src={cube2} alt="cube2" className='cube2' />

    </section>
  )
}

export default ExploreContent
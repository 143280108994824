import React from 'react'

const Banner = () => {
  return (
    <section className='banner-area' id="">
      <h1>Always Available for Your <br />
        Plastic needs </h1>
      <h4>A partner and
        partner packaging</h4>
    </section>
  )
}

export default Banner
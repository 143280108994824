import React, { useState } from 'react'
import location from '../assets/images/location (2) 1.svg';
import mail from '../assets/images/Group.svg';
import phone from '../assets/images/Vector (1).svg';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [emailData, setEmailData] = useState({
    from_name: '',
    to_name: 'PNP Packaging',
    message: '',
    phone: '',
  });
  const templateId = 'template_feujudo';
  const userId = 'user_JASwntk5gnjYPSUWeIg1i';
  const serviceId = 'service_1quxaao';
  const sendMail = () => {
    emailjs.send(serviceId, templateId, emailData, userId)
      .then((result) => {
        alert('Message Sent, We will get back to you shortly', result.text);
      }, (error) => {
        alert('An error occurred, Please try again', error.text);
      });
  }
  return (
    <section className='contact-us-section container-fluid' id="contact-us">
      <div className='contact-us-area container'>
        <div className='top-section'>
          <h1 className='title'>Contact Us</h1>
        </div>
        <div className='middle-section row'>
          <div className='left-section col-md-5'>
            <h1>Get In Touch</h1>
            <p>Thank you for choosing Partner and Partner Packaging as
              your trusted provider of plastic bags, sacks and other packing solutions. We would like to express our deepest appreciation for your trust in our company</p>
          </div>
          <div className='right-section col-md-7'>
            <div className='form'>
              <form>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>Name</label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      placeholder='Enter your name'
                      value={emailData.from_name}
                      onChange={(e) => setEmailData({ ...emailData, from_name: e.target.value })}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Enter your email'
                      value={emailData.email}
                      onChange={(e) => setEmailData({ ...emailData, email: e.target.value })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='phone'>Phone</label>
                    <input
                      type='text'
                      id='phone'
                      name='phone'
                      placeholder='Enter your phone number'
                      value={emailData.phone}
                      onChange={(e) => setEmailData({ ...emailData, phone: e.target.value })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label htmlFor='message'>Message</label>
                    <textarea
                      name='message'
                      id='message'
                      cols='30'
                      rows='10'
                      placeholder='Enter your message'
                      value={emailData.message}
                      onChange={(e) => setEmailData({ ...emailData, message: e.target.value })}
                    ></textarea>
                  </div>
                </div>
                <div className='row'>
                  <div className='btn btn-secondary w-25 ' onClick={sendMail}>
                    Submit
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='bottom-section'>
          <div className='contact-card'>
            <img src={location} alt="location" />
            <p>Nr. Machohalli Gate,Magadi Road,<br /> Bangalore- 560091</p>
          </div>
          <div className='contact-card'>
            <img src={phone} alt="location" />
            <p>+91  99018  49871</p>
          </div>
          <div className='contact-card'>
            <img src={mail} alt="location" />
            <p>jayanthjain.pp@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
import React from 'react'
import logo from '../assets/images/logo_white.svg';
import line from '../assets/images/Line 18.svg';
import partner from '../assets/images/Partner & Partner Packaging.png';

const Footer = () => {
  return (
    <footer class="section bg-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 left-section" >
            <img src={logo} alt="logo" />
            <div></div>
            <img src={line} alt="line" />
            <img src={partner} alt="partner" />
          </div>
          <div class="col-lg-3">
          </div>
          <div class="col-lg-2">
            <div class="">
              <h6 class="footer-heading text-uppercase text-white">Helpful Links</h6>
              <ul class="list-unstyled footer-link mt-4">
                <li><a href="/#home">Home </a></li>
                <li><a href="#about-us">About Us</a></li>
                <li><a href="#why-us">Why Us</a></li>
                <li><a href="#products">Products</a></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="">
              <h6 class="footer-heading text-uppercase text-white">Contact Us</h6>
              <ul className='list-unstyled footer-link mt-4'>
                <li><a className="contact-info" href='tel:+919901849871'>+91  99018  49871</a></li>
                <li><a className='contact-info' href='mailto:jayanthjain.pp@gmail.com'>jayanthjain.pp@gmail.com</a></li>
              </ul>


              <div class="mt-5">
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="text-center mt-5">
        <p class="footer-alt mb-0 f-14">2023 © Partner & Partner Packaging, All Rights Reserved</p>
      </div>
    </footer>
  )
}

export default Footer
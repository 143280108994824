import React from 'react'
import '../assets/styles/product.scss';
import { ProductDetail, RelatedProduts } from '../components';

const Product = () => {
  return (
    <main className='product-detail'>
      <ProductDetail />
      <RelatedProduts />
    </main>
  )
}

export default Product
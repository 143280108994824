import React from 'react'
import settings from '../assets/images/settings 1.svg';
import shakeHand from '../assets/images/shake-hand 1.svg';
import checkList from '../assets/images/checklist 1.svg';
import insurance from '../assets/images/insurance 1.svg';
import career from '../assets/images/career 1.svg';
import vector from '../assets/images/vector_strategy.png';

const Strategy = () => {
  const strategies = [
    {
      id: 1,
      title: 'Power of Purpose',
      description: 'our goal is to provide enhanced service,build strong relationship, and drive profitability',
      icon: settings
    },
    {
      id: 2,
      title: 'Vision',
      description: 'Provide quality services that exceeds the expectations of customers.',
      icon: shakeHand
    },
    {
      id: 3,
      title: 'Mission Statement',
      description: 'To Build long term relationship with our customers and provide expectational customer service',
      icon: checkList
    },
    {
      id: 1,
      title: 'Core Values',
      description: 'believe in treating our customers with respect, growing through creativity ,innovation and  honesty',
      icon: insurance
    },
    {
      id: 1,
      title: 'Power of Purpose',
      description: 'Regional expansion in the field of packaging and develop a strong base of key customers.',
      icon: career
    }
  ]
  return (
    <section className='strategy-section container-fluid'>
      <img src={vector} alt='vector' className='vector' />
      <div className='strategy-area container'>
        <div className='top-section'>
          <h1 className='title'>Strategy</h1>
        </div>
        <div className='bottom-section'>
          {
            strategies.map((strategy, index) => (
              <div className='strategy-card'>
                <div className='top'>
                  <img src={strategy.icon} alt='icon' />
                  <h3>{strategy.title}</h3>
                </div>
                <div className='bottom'>
                  <p>{strategy.description}</p>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    </section >
  )
}

export default Strategy